.metrics-and-dimensions,
.default-user-source {
     .list-rules {
          .row-rule {
               min-height: 46px;
          }
     }

     .btn-show-default {
          position: relative;
          color: #7f8c9a;
          font-size: 13px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0.4px;
          padding: 0;
          text-transform: inherit;
          text-decoration: underline;

          &::after {
               content: '';
               position: absolute;
               top: 50%;
               right: -15px;
               width: 6px;
               height: 6px;
               transform: translateY(-50%) rotate(135deg);
               border: 2px solid #7f8c9a;
               border-bottom: none;
               border-left: none;
          }

          &.show::after {
               top: calc(50% + 3px);
               transform: translateY(-50%) rotate(-45deg);
          }

          &:active {
               color: #7f8c9a;
          }
     }
}

.dimension-detail {
     .card {
          margin-bottom: 10px;

          &.hide {
               box-shadow: none !important;

               .card-header {
                    border-radius: 12px;
               }
          }
     }

     .form-group div[class*='-container'] div[class*='-control'] {
          padding-right: 20px;
     }

     .dimension-source {
          .custom-checkbox {
               margin-top: 8px;

               .custom-control-label {
                    line-height: 21px;
               }
          }
     }

     .available-scopes {
          form {
               margin-top: 15px;
          }

          .row-item {
               position: relative;
               margin-left: 0;
               margin-bottom: 25px;
               row-gap: 10px;
               flex-wrap: nowrap;

               button {
                    padding: 0;

                    .icon-add {
                         margin-top: 9px;
                    }
               }

               .invalid-feedback {
                    position: absolute;
                    bottom: -18px;
               }

               .col-item {
                    width: 100%;
                    max-width: 340px;
                    margin-right: 15px;

                    div[class*='-container'] {
                         margin-bottom: 0;
                    }
               }

               .check-box-conversion {
                    width: 100%;
                    max-width: 350px;
               }

               .select-scope-related {
                    max-width: 350px;
                    min-width: 350px;
                    .info-title {
                         display: inline-block;
                    }
                    .tooltip-wrapper {
                         position: relative;
                         display: inline-block;
                         padding-left: 5px;
                    }
                    .tooltip-wrapper > svg path {
                         fill: #20a8d8 !important;
                    }
               }

               .actions {
                    display: flex;
                    justify-content: flex-end;
                    flex-direction: column;
                    margin-bottom: 2px;

                    button .icon-delete {
                         margin-left: -3.5px;
                    }
               }
          }
     }

     .examples {
          .list-examples-wrapper {
               min-height: 43px;
          }

          .list-examples {
               border: 1px solid rgb(226, 230, 233);
               border-radius: 12px;
               padding: 10px;
               overflow-y: auto;
               overflow-x: hidden;
               max-height: 350px;

               &::-webkit-scrollbar {
                    width: 3px;
               }

               &::-webkit-scrollbar-track {
                    width: 3px;
                    background-color: #e2e6e9;
                    border-radius: 2px;
                    margin-top: 5px;
                    margin-bottom: 5px;
               }

               &::-webkit-scrollbar-thumb {
                    background: #73818f;
                    width: 3px;
                    border-radius: 12px;
                    border: none;
               }

               ul {
                    margin-bottom: 0;
                    margin-left: -21px;
               }
          }
     }
}

.external-data {
     .close-filter {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 4px;
          background: white;
          padding-left: 8px;
          display: block;
          padding-right: 4px;
     }
     .k-grid {
          border-radius: 12px;
          overflow: hidden;
     }
     .k-grid-content {
          overflow: hidden;
     }
     .k-table-thead {
          background: #8d9ba8;
          color: #424242;
     }
     .k-grid-header {
          padding-right: 0;
     }
     .k-table {
          .k-column-title {
               font-weight: 500;
          }
     }
     .k-grid-header-wrap {
          border-top-right-radius: 12px;
          border-top-left-radius: 12px;
     }
     .k-grid-norecords {
          width: 100% !important;
     }
     .k-grid-norecords-template{
          border: initial;
     }
     .view-detail {
          span {
               cursor: pointer;
               text-decoration: underline;
          }
     }

     .rule-layout {
          position: relative;

          .rule-loading-overlay {
               position: absolute;
               width: 100%;
               height: 100%;
               top: 0;
               left: 0;
               display: flex;
               align-items: center;
               justify-content: center;
               background-color: rgba(240, 243, 245, 0.4);
               z-index: 10;
          }
     }
}
.default-user-source {
     &-list {
          display: flex;
          flex-wrap: wrap;
          gap: 10px 30px;
          margin: 24px 0px 0px;
          padding: 40px;
          background: #f0f3f5;
          border-radius: 12px;
     }

     .variable-input-group {
          width: calc((100% / 5 - 30px));

          input[disabled] {
               color: #333;
               font-family: Roboto;
               font-size: 14px;
               font-weight: 400;
               line-height: 17px;
               background: #fff;
          }
     }
}

.account-connected-detail {
     h6 {
          padding-bottom: 15px;
     }
     .sign-in {
          margin-bottom: 20px;
     }
     .account-connection {
          display: flex;
          align-items: center;
          padding-bottom: 15px;
          .avatar {
               margin-right: 20px;
               width: 36px;
               height: 36px;
          }
     }
     .data-account {
          display: flex;
          flex-direction: column;
     }
     .btn-save-account {
          text-transform: lowercase;
          text-decoration: underline;
     }
     .btn-delete-connect {
          text-decoration: underline;
          text-transform: capitalize;
     }
     .tooltip-wrapper {
          position: relative;
          font-weight: 400;
     }
     .invalid-feedback {
          display: flex;
     }
}

.rule-detail {
     &.object-import-detail {
          .object-detail-column {
               .react-select__control:not(.react-select__control--is-disabled) [class*='-placeholder'] {
                    color: #2f353a !important;
               }
          }

          .f-18 {
               font-size: 18px;
          }

          .text-red-800 {
               color: #1e40af !important;
          }
          
          .bg-red {
               &-50 {
                    background-color: #eff6ff !important;
               }
          }

          .button-setup-rule {
               color: white;
               background-color: #1e40af !important;
               font-weight: 500;
               font-size: 14px !important;
               outline: none;

               &:hover {
                    background-color: #1e3a8a !important;
               }
          }

          .border-red-300 {
               border-color: #93c5fd !important;
          }

          .w-4 {
               width: 16px !important;
          }

          .h-4 {
               height: 16px !important;
          }
     }
}

.object-import-detail {
     .card {
          &-header {
               h5 {
                    min-width: 200px;
               }
          }
          &-body {
               form div[class$='container'] {
                    border-radius: 12px;
                    // overflow: hidden;

                    .css-1fhf3k1-control {
                         background-color: #e4e7ea;

                         div[class$='singleValue'] {
                              color: #212c36;
                         }
                    }
               }
          }
     }
     .object-detail {
          &-table {
               display: table;
               width: 100%;
          }
          &-header {
               display: table-header-group;
               font-weight: 500;

               > div {
                    padding-bottom: 8px;

                    &:nth-child(2) {
                         text-align: center;
                    }

                    &:nth-child(3) {
                         padding-left: 60px;
                    }
               }
          }
          &-body {
               display: table-row-group;

               .add-icon {
                    display: flex;
                    align-items: center;
                    margin-top: 5px;

                    > svg {
                         width: 16px;
                         height: 16px;
                    }

                    .text-add {
                         text-transform: none;
                         margin-top: 2px;
                    }
               }

               .group-button-add-delete {
                    margin-left: 10px;

                    svg {
                         width: 24px;
                         height: 24px;
                    }
               }
          }
          &-row {
               display: table-row;

               > div {
                    padding: 6px 0;
               }
               &.last-row {
                    .column-1 {
                         width: 32.5% !important;
                    }
                    .column-3 {
                         // width: 60% !important;
                         color: #2f353a;
                         font-weight: 500;
                         font-style: normal !important;
                    }
                    .add-icon {
                         .text-add {
                              margin-top: 2px;
                         }
                         > svg {
                              width: 16px;
                              height: 16px;
                         }
                    }
               }
          }

          &-group {
               display: flex;
               gap: 20px;
               padding: 0 20px 20px !important;
               width: 200%;

               &.target-fields{
                    padding: 10px 0 10px !important;
               }

               .object-field-options {
                    width: 25% !important;
               }

               .object-field-condition{
                    width: 150px;
               }

               .object-field-value{
                    .sf__options {
                         width: 150px;
                    }
               }
          }
          &-column {
               display: table-cell;
               vertical-align: top;

               &.sf-column-ll-fields {
                    width: 18% !important;
               }

               &:nth-child(1) {
                    width: 26%;
               }

               &.first-col {
                    width: 35%;
               }

               &.pipeline-field:nth-child(1) {
                    width: 10%;
               }

               &.pipeline-field:nth-child(2) {
                    width: 450px;
               }

               &.pipeline-field:nth-child(3) {
                    width: 500px;
               }
               
               &:nth-child(2) {
                    width: 31%;
                    padding-inline: 20px;
               }

               &.second-col {
                    width: 18%;
               }

               &:nth-child(3) {
                    width: 41%;
                    padding-left: 20px;
               }

               [class*='-placeholder'] {
                    color: #2f353a !important;
               }

               .form-check-input{
                    &:hover {
                         cursor: pointer;
                    }
               }
          }
     }

     .object-field {
          &-select-group {
               display: flex;
               justify-content: space-between;
          }
          &-options {
               &:nth-child(1) {
                    width: 59%;
               }

               &:nth-child(2) {
                    width: 39%;
               }

               div[class*='singleValue'] {
                    max-width: calc(100% - 40px);
               }
          }

          &-matching {
               margin-top: 30px;

               .object-detail-header {
                    > div:nth-child(3) {
                         padding-left: 20px;
                         font-weight: normal;
                    }
               }

               .custom-checkbox {
                    align-items: center;
                    .custom-control-label {
                         line-height: 17px;
                    }
               }
          }
     }

     .object-other-fields {
          max-width: 970px;
          // max-height: 540px;
          padding: 20px;
          border: 1px solid #eeee;
          border-radius: 5px;
          // overflow-y: auto;

          &::-webkit-scrollbar {
               width: 3px;
               border-radius: 4px;
          }

          &::-webkit-scrollbar-track {
               border-radius: 4px;
               width: 3px;
               background-color: #e2e6e9;
               box-shadow: none;
          }

          &::-webkit-scrollbar-thumb {
               background: #73818f;
               border-radius: 4px;
               border: none;
               width: 3px;
          }

          &::-webkit-scrollbar-thumb:hover {
               background: #666666;
          }

          .object-detail-header {
               text-align: left;
               > div:nth-child(3) {
                    padding-left: 30px;
               }
          }

          .object-detail-column {
               padding: 4px 0;

               &:nth-child(1) {
                    width: 30%;
                    padding-right: 10px;
               }
               &:nth-child(2) {
                    width: 5%;
               }
               &:nth-child(3) {
                    width: 13%;
                    font-style: italic;
                    padding-inline: 10px;
               }
               &:nth-child(4) {
                    width: 35%;
                    padding-left: 20px;
               }
               &:nth-child(5) {
                    width: 10%;
               }

               input[type='checkbox'] {
                    height: 14px;
               }
          }
     }

     .section-other-fields {
          max-width: 900px;
          border: 1px solid #eeee;
          border-radius: 5px;
     }

     .btn-link {
          border: none;
          padding: 0;
          text-transform: none;
          text-decoration: underline;
     }

     .external-data-loading {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          height: 300px;
          max-width: 360px;
          margin: 0 auto;

          > p {
               font-size: 18px;
               line-height: 24px;
          }

          > i {
               font-size: 40px;
               font-weight: 500;
               padding: 10px 0;
               animation: rotateLoading 2s linear infinite;
               -webkit-animation: rotateLoading 2s linear infinite;
          }

          @keyframes rotateLoading {
               from {
                    -webkit-transform: rotate(0deg);
               }
               to {
                    -webkit-transform: rotate(360deg);
               }
          }

          @-webkit-keyframes rotateLoading {
               from {
                    -webkit-transform: rotate(0deg);
               }
               to {
                    -webkit-transform: rotate(360deg);
               }
          }
     }

     .object-historical-sync {
          .input-group {
               max-width: 300px;
               align-items: center;
               margin-top: 8px;

               .react-datepicker-wrapper {
                    width: 100%;
                    padding: 0;

                    input {
                         height: 40px;
                    }
               }

               .historical-sync-icon {
                    position: absolute;
                    right: 15px;
                    z-index: 1;
                    margin: 0;

                    > i {
                         font-size: 18px;
                         color: #333333;
                    }
               }
          }
     }
}

.traffic-channel {
     &-detail {
          .sources {
               &-search {
                    margin-bottom: 12px;
                    width: 254px;

                    input.form-control {
                         border-radius: 6px;
                         border: 1px solid #ebedf3;
                         height: 41px;
                    }
               }

               &-list-box {
                    display: flex;
                    position: relative;
               }

               &-list-wrap {
                    flex: 1;
                    max-width: 254px;

                    &.other-channel {
                         max-width: 350px;
                    }
               }

               &-list-title {
                    display: block;
                    color: #18222b;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 24px;
                    padding: 8px 0 8px 14px;
                    border: 1px solid #ebedf3;
                    border-bottom: 0;
                    border-radius: 6px 6px 0 0;
               }

               &-list-group {
                    height: 300px;
                    overflow-y: auto;
                    border: 1px solid #ebedf3;
                    border-radius: 0 0 6px 6px;

                    &::-webkit-scrollbar {
                         width: 3px;
                         border-radius: 4px;
                    }

                    &::-webkit-scrollbar-track {
                         border-radius: 4px;
                         width: 3px;
                         background-color: #e2e6e9;
                         box-shadow: none;
                    }

                    &::-webkit-scrollbar-thumb {
                         background: #73818f;
                         border-radius: 4px;
                         border: none;
                         width: 3px;
                    }

                    &::-webkit-scrollbar-thumb:hover {
                         background: #666666;
                    }

                    .no-source {
                         color: #2f353a;
                         display: block;
                         padding: 10px 0;
                         text-align: center;
                    }
               }

               &-list-item {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    width: 100%;

                    &:not(:last-child) {
                         border-bottom: 1px solid #ebedf3;
                    }

                    &--checked {
                         background-color: #f3f6f9;
                    }
               }

               &-checkbox {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    padding: 11px 14px;
                    margin: 0 !important;
                    cursor: pointer;

                    &-wrap {
                         display: flex;
                         flex-direction: row-reverse;
                         justify-content: space-between;
                         align-items: center;

                         span {
                              text-align: left;
                              word-break: break-all;
                              display: block;
                         }
                    }

                    &-icon {
                         display: flex;
                         align-items: center;
                         justify-content: center;
                         flex: 0 0 19px;
                         width: 19px;
                         height: 19px;
                         margin-right: 12px;
                         border-radius: 50%;
                         background: #c8ced3;

                         svg {
                              visibility: hidden;
                         }
                    }

                    input[type='checkbox'] {
                         appearance: none;
                         -webkit-appearance: none;

                         &:checked + .sources-checkbox-wrap .sources-checkbox-icon {
                              background: #4dbd74;

                              svg {
                                   visibility: visible;
                              }
                         }
                    }

                    span {
                         color: #2f353a;
                         font-size: 13px;
                         line-height: 18px;
                         margin-right: auto;
                    }
               }

               &-button {
                    display: flex;
                    flex-direction: column;
                    margin: auto 20px;

                    &-icon {
                         color: #b5b5c3;
                         background-color: #f3f6f9;
                         border-radius: 6px;
                         padding: 5px 7px;

                         svg {
                              width: auto;
                              height: auto;
                              margin: 0;
                         }

                         &:not(:last-child) {
                              margin-bottom: 8px;
                         }

                         &:not(:disabled) {
                              &:hover {
                                   color: #20a8d8;
                              }
                         }

                         > i {
                              font-weight: 600;
                         }
                    }
               }
          }
     }
}

@media screen and (max-width: 1400px) {
     .default-user-source {
          &-list {
               gap: 10px 20px;
          }

          .variable-input-group {
               width: calc((100% / 4 - 20px));
          }
     }
}

.segment {
     .list-rules {
          .row-rule {
               min-height: 46px;
          }
     }
     .accordion-item {
          border: unset;
     }

     .btn-show-default {
          position: relative;
          color: #7f8c9a;
          font-size: 13px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0.4px;
          padding: 0;
          text-transform: inherit;
          text-decoration: underline;

          &::after {
               content: '';
               position: absolute;
               top: 50%;
               right: -15px;
               width: 6px;
               height: 6px;
               transform: translateY(-50%) rotate(135deg);
               border: 2px solid #7f8c9a;
               border-bottom: none;
               border-left: none;
          }

          &.show::after {
               top: calc(50% + 3px);
               transform: translateY(-50%) rotate(-45deg);
          }
     }
     .default-segments {
          padding-bottom: 14px;
          margin-bottom: 70px;
     }
     .segment-container {
          margin: 0 -20px;
          &.height {
               height: 150px;
          }
          .default-segments {
               background-color: #f0f3f5;
          }

          .segment-content {
               .list-rules {
                    padding: 0 20px;
               }
               .default-segments {
                    .headline {
                         padding: 20px 20px 0;
                    }

                    .row-rule {
                         background-color: #fff;
                         &.system-default {
                              background-color: unset;
                              h3 {
                                   font-size: 18px;
                                   font-weight: 600;
                                   line-height: 27px;
                                   padding: 0;
                              }
                         }
                    }
               }
               .btn-create-rule {
                    margin-left: 20px;
               }
               .pagination {
                    padding: 10px 20px 0px;
               }
          }
     }
}

.date-range-segment {
     background: #f0f3f5;
     border-radius: 10px;
     padding: 10px 20px;
     margin-top: 15px;
     .content {
          p {
               color: #73818f;
               font-family: Roboto;
               font-size: 14px;
               font-weight: 400;
               line-height: 17px;
               letter-spacing: 0.5px;
          }
     }

     .invalid-feedback {
          display: block;
     }

     .select-date-range {
          .select-condition {
               .form-control {
                    background-color: #fff;
               }
          }

          .pervious-period {
               &.input-value-rules {
                    max-width: 200px;
               }
               .form-control {
                    text-align: center;
               }
          }
          .input-value-rules {
               max-width: 240px;
          }
     }
}

.relationship {
     .select-type {
          width: 230px !important;
          .form-control {
               width: 100%;
               max-width: 200px;
               min-width: 0;
          }
     }
     .content {
          white-space: nowrap;
     }
}

.rule-detail.segment-detail {
     .select-event-variable {
          width: 100%;
          max-width: 210px !important;
          min-width: 0;
     }
     .relationship {
          .select-event-variable {
               min-width: 210px;
          }
          .input-value-rules {
               @media (min-width: 1400px) {
                    min-width: 300px !important;
               }
          }
     }
}
.secondary-object {
     .react-select__control .react-select__value-container {
          padding-right: 0 !important;
     }
}
.segment-date {
     .invalid-feedback {
          display: block;
     }
}

.full-screen {
     .segment-detail {
          .cvr-create-new {
               .conditions-group {
                    .conditions-match {
                         .select-type,
                         &.web-conversion {
                              min-width: 0 !important;
                              max-width: 230px !important;
                              width: 100% !important;
                         }
                    }
               }
          }
     }
}

.segment-detail {
     .cvr-create-new {
          .conditions-group {
               .conditions-match {
                    .select-type {
                         min-width: 0;
                         max-width: 265px;
                         width: 100%;
                         &.web-conversion {
                              @media (min-width: 1400px) {
                                   max-width: 220px;
                              }
                         }
                         &.select-condition {
                              max-width: 190px;
                              &.previous-number {
                                   min-width: 0;
                                   max-width: 280px;
                                   width: 100%;
                              }
                         }
                         &.condition-focus {
                              min-width: 200px;
                         }
                    }
                    .input-value-rules {
                         min-width: 0;
                         max-width: 300px;
                         width: 100%;
                         flex: unset;
                         @media (min-width: 1400px) {
                              min-width: 220px;
                         }
                         input {
                              text-overflow: ellipsis;
                         }
                         &.date-range {
                              max-width: 200px;
                         }
                         .invalid-feedback {
                              display: block;
                         }
                    }
               }
          }
     }
     .cvr-step-card {
          .select-type {
               &.condition-focus {
                    min-width: 0;
                    max-width: 200px;
                    width: 100%;
               }
          }
     }

     .select-rule-include {
          width: 100% !important;
          min-width: 0 !important;
          max-width: 140px !important;
     }
     .select-rule-scop {
          width: 100% !important;
          min-width: 0 !important;
          max-width: 160px !important;
     }

     .select-type {
          .select-listener {
               margin-bottom: 0px;
          }
     }
     .no-options {
          &:hover {
               background-color: unset !important;
               cursor: unset;
          }
     }
     
}

.rule-detail {
     .c-icon:not(.c-icon-c-s):not(.c-icon-custom-size) {
          &.icon-delete.disabled {
               opacity: 0.5;
          }

          // &.icon-add {
          //      width: 21px;
          //      height: 21px;
          // }

          // &.icon-add-gray {
          //      width: 11px;
          //      height: 11px;
          // }
     }

     .icon-sort {
          transform: rotate(180deg);
          height: 12px;

          &.asc,
          &.desc {
               path {
                    fill: #000000;
               }
          }

          &.desc {
               transform: rotate(0);
          }
     }
}

.react-datepicker-popper {
     z-index: 20;
}

.sf__options {
     .sf_new--field__option:last-child:has(.option-create-categories), .react-select__option:last-child:has(.option-create-categories) {
          position: sticky;
          bottom: -4px;
          background-color: #ffffff;

          button {
               width: 100%;
               text-align: left;
          }
     }
}
.sales-conversion {
     .list-rules {
          .row-rule {
               min-height: 46px;
          }
     }
     .accordion-item {
          border: unset;
     }

     .btn-show-default {
          position: relative;
          color: #7f8c9a;
          font-size: 13px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0.4px;
          padding: 0;
          text-transform: inherit;
          text-decoration: underline;

          &::after {
               content: '';
               position: absolute;
               top: 50%;
               right: -15px;
               width: 6px;
               height: 6px;
               transform: translateY(-50%) rotate(135deg);
               border: 2px solid #7f8c9a;
               border-bottom: none;
               border-left: none;
          }

          &.show::after {
               top: calc(50% + 3px);
               transform: translateY(-50%) rotate(-45deg);
          }
     }
     .default-sale-conversion {
          padding-bottom: 14px;
     }
     .sale-conversion-container {
          margin: 0 -20px;
          border: unset !important;
          overflow: unset !important;
          &.height {
               height: 150px;
          }
          .default-sale-conversion {
               background-color: #f0f3f5;
          }

          .sale-conversion-content {
               .list-rules {
                    padding: 0 20px;
               }
               .default-sale-conversion {
                    .headline {
                         padding: 20px 20px 0;
                    }

                    .row-rule {
                         background-color: #fff;
                         &.system-default {
                              background-color: unset;
                              h3 {
                                   font-size: 18px;
                                   font-weight: 600;
                                   line-height: 27px;
                                   padding: 0;
                              }
                         }
                    }
               }
               .btn-create-rule {
                    margin-left: 20px;
               }
               .pagination {
                    padding: 10px 20px 0px;
               }
          }
     }
}

.sales-conversion-select {
     .tooltip-wrapper > svg {
          path {
               fill: #20a8d8 !important;
          }
     }
}

.sf__options {
     .sf_new--field__option:last-child, .react-select__option:last-child:has(.option-create-categories) {
          position: sticky;
          bottom: -4px;
          background-color: #ffffff;

          button {
               width: 100%;
               text-align: left;
          }
     }
}

.flex-shrink-0 {
     flex-shrink: 0;
}

.sfpreview {
     &__section {
          border: 1px solid #dadfe3;
          border-radius: 12px;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 60px 0px;
     }
}

.sf_edit--field {
     position: absolute;
     top: 46%;
     right: 5px;
     transform: translateY(-50%);
     
     &.field__select {
          right: 25px;
     }

     &.field__input {
          right: 10px;
     }
}

.sf_new--field__value-container {
     padding: 3px 6px !important;
}

.sf_new--field__single-value {
     .text-danger {
          display: none;
     }
}

.sf-add-custom {
     div[class*='singleValue'] {
          max-width: calc(100% - 20px);
     }
}