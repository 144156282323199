.select-insert-variable {
     p {
          margin-top: 0;
     }

     .dashboard {
          .insert-variable-dropdown{
               box-shadow: -10px 20px 25px 0px rgba(0, 0, 0, 0.1) !important;
               border-radius: 5px !important;
          }
     }

     .insert-variable-dropdown {
          margin-top: -1px;
          list-style: none;
          position: absolute;
          left: 0;
          padding: 0px;
          z-index: 99;
          border: 1px solid #e4e7ea !important;
          box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.13) !important;
          max-height: 280px;
          background-color: #fff;
          width: 100%;
          border-radius: 12px;
          overflow: hidden;
          margin-bottom: 1rem;

          &.dropdown-wrapper {
               min-width: 256px;
               border-radius: 4px;
               right: 0;
               left: unset;
               margin-top: 3px;
               top: -3px;
          }

          .get-new-field {
               .option-create-categories{
                    width: 100%;
                    text-align: left;
               }
          }

          ul {
               margin-top: -1px;
               list-style: none;
               padding: 0px;
               max-height: 265px;
               overflow-y: auto;
               background-color: #fff;
               width: 100%;
               border-radius: 12px;
               overflow-x: hidden;
               margin-bottom: 0;

               .type-variable {
                    strong {
                         word-break: break-word;

                         &:nth-of-type(2) {
                              white-space: nowrap;
                         }
                    }
               }

               &.have-scroll {
                    width: calc(100% - 5px);

                    li {
                         padding-right: 9px;
                    }
               }

               &::-webkit-scrollbar {
                    width: 4px;
                    padding-right: 8px;
               }

               &::-webkit-scrollbar-track {
                    width: 4px;
                    background-color: #e2e6e9;
                    border-radius: 2px;
                    margin-top: 8px;
                    margin-bottom: 8px;
                    padding-right: 8px;
               }

               &::-webkit-scrollbar-thumb {
                    background: #73818f;
                    width: 4px;
                    border-radius: 12px;
                    border: none;
                    min-height: 50px;
               }
          }

          li {
               list-style: none;
               word-break: break-all;
               min-height: 38px;
               padding: 0 17px 0 17px;
               cursor: pointer;

               &.child {
                    padding-left: 30px;
               }

               span {
                    color: #7f8c9a;
               }

               &.variable-filter {
                    &:hover {
                         background: #ffffff;
                    }
               }

               &:hover {
                    background: #f3f5f6;

                    span {
                         color: #20a8d8 !important;
                    }
               }

               &:last-child {
                    border-bottom: none !important;
                    &.custom {
                         background-color: #f5f5f5;
                    }
               }

               &.active-variable {
                    background-color: #f3f5f6;

                    span {
                         color: #20a8d8 !important;
                    }
               }
          }

          .type-variable {
               svg {
                    &.icon:not(.icon-c-s):not(.icon-custom-size) {
                         height: 8px;
                         width: 8px;
                    }
               }
          }

          .variable-filter {
               padding: 10px 17px 10px 17px;
               position: sticky;
               top: 0;
               z-index: 10;
               background-color: #fff;
          }
     }

     .select-wrapper {
          .select-multiple-variable-field {
               white-space: unset !important;
          }

          .browser-step4-dropdown ul,
          .insert-variable-dropdown {
               .variable-filter input.form-control {
                    border-color: #e2e6e9 !important;
               }
          }
     }
     &.segment-step1 {
          ul.have-scroll {
               width: 100%;
          }
     }
}

.select-multiple-variable-field {
     .place-holder-select {
          height: 30px;
          display: flex;
          align-items: center;
          color: gray !important;
     }

     ul {
          padding: 0;
          margin-bottom: 0;
          display: flex;
          flex-wrap: wrap;
     }

     li {
          list-style: none;
          color: hsl(0, 0%, 20%);
          background-color: #f0f3f5;
          border-radius: 9px;
          padding: 3.5px 21px 3.5px 6px;
          text-overflow: ellipsis;
          white-space: nowrap;
          box-sizing: border-box;
          font-size: 85%;
          margin: 2px 2px 4px;
          position: relative;

          .icon-remove-multi {
               height: 100%;
               padding: 0 4px 0 11px;
               min-height: 21px;
               position: absolute;
               right: 0;
               min-width: 18px;
               top: 50%;
               transform: translateY(-50%);

               &:hover {
                    background-color: #ffbdad;
                    border-radius: 2px;
               }

               svg {
                    color: #808080;
                    margin: 0;
                    height: 10px;
                    width: 10px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    &:hover {
                         color: #de350b;
                    }
               }
          }
     }
}

.rule-detail {
     .select-react-customize {
          .select-wrapper {
               .stretch-container {
                    &.select-multiple-variable-field {
                         padding-top: 3px;
                         padding-bottom: 2px;
                    }
               }
          }
     }
}

.object-detail-column [class*='-singleValue'] {
     max-width: calc(100% - 34px);
}
.width_200{
     width: 220px;
}

.width_100{
     width: 100px;
}
