body #storybook-root {
     display: flex;
     justify-content: center;
     align-items: center;
     height: calc(100% - 32px);
     .icons-search {
          display: flex;
          position: fixed;
          justify-content: center;
          align-items: center;
          width: 100%;
          &-wrap {
               position: relative;
               input {
                    min-width: 500px;
                    height: 42px;
                    border-radius: 22px;
                    outline: none !important;
                    box-shadow: none !important;
                    border: 4px solid #183153;
                    padding: 0 40px;
               }
               button {
                    position: absolute;
                    top: calc(50% - 14px);
                    right: 12px;
                    border: none;
                    background: none;
               }
               .icon-search {
                    position: absolute;
                    top: calc(50% - 12.5px);
                    left: 10px;
                    width: 25px;
                    height: 25px;
               }
               .icon-clear {
                    border: 1px solid transparent;
                    border-radius: 50%;
                    width: 25px;
                    height: 25px;
               }
          }
     }
     .wrapper {
          width: 100%;
          flex-wrap: wrap;
          gap: 10px 18px;
          margin-top: 70px;
          .box-icon {
               display: flex;
               flex-direction: column;
               align-items: center;
               justify-content: space-evenly;
               width: 180px;
               height: 180px;
               background-color: #fff;
               padding: 20px;
               border-radius: 8px;
               cursor: pointer;
               animation: 0.5s fadeIn;
               &:hover {
                    background-color: #dfdfdf;
               }
               svg {
                    margin-bottom: 10px;
                    width: 35px;
                    height: 35px;
               }
               .fadein {
                    font-size: 24px;
                    font-weight: 600;
                    animation: 0.5s fadeIn;
               }
          }
     }
}

.status {
     &-item {
          width: 8px;
          height: 8px;
          border: 1px solid #fff;
          border-radius: 50%;
          &.sm {
               width: 8px;
               height: 8px;
               border-width: 1px;
          }
          &.lg {
               width: 30px;
               height: 30px;
               border-width: 4px;
          }
          &.xl {
               width: 50px;
               height: 50px;
               border-width: 6px;
          }
     }
}

.tippy {
     &-box.custom-tooltip-hover {
          position: relative !important;
          // min-width: 192px;
          min-height: fit-content;
          background-color: transparent !important;

          &.segment {
               &-desc {
                    .tippy-content {
                         border-top-left-radius: 0px;
                    }
                    .tippy-svg-arrow {
                         top: -1px !important;
                    }
               }
               &-sticky {
                    .tippy-svg-arrow {
                         left: 0px !important;
                    }
               }
          }

          .tippy-content {
               border-radius: 8px;
               .badge {
                    &-tooltip {
                         &-wrapper {
                              display: flex;
                              padding: 8px;
                              color: #fff;
                              font-size: 12px;
                              line-height: 16px;
                              flex-direction: column;
                              gap: 8px;
                              min-width: 200px;
                         }
                         &-head {
                              font-weight: 700;
                              gap: 4px;
                              .icon {
                                   flex-shrink: 0;
                              }
                              span {
                                   text-wrap: auto;
                                   text-align: left;
                                   &.hasIconClose {
                                        width: 78%;
                                   }
                              }
                         }
                         &-des {
                              font-weight: 400;
                              text-wrap: auto;
                              text-align: left;
                         }
                         &-icon-clear {
                              top: 6px;
                              right: 8px;
                              cursor: pointer;
                         }
                    }
               }
          }
          .tippy-svg-arrow {
               width: 9px;
               height: 9px;
               transform: unset !important;
               line-height: 1.4;
               font-size: 14px;
          }
          &.top-start {
               .tippy-content {
                    border-radius: 8px 8px 8px 0;
               }
               .tippy-svg-arrow {
                    bottom: 1px !important;
                    left: 5px !important;
               }
          }
          &.top-end {
               .tippy-content {
                    border-radius: 8px 8px 0 8px;
               }
               .tippy-svg-arrow {
                    right: 5px !important;
                    bottom: 1px !important;
                    left: unset !important;
               }
          }
          &.bottom-end {
               .tippy-content {
                    border-radius: 8px 0 8px 8px;
               }
               .tippy-svg-arrow {
                    top: -10px !important;
                    right: 5px !important;
                    left: unset !important;
               }
          }
          &.bottom-start {
               .tippy-content {
                    border-radius: 0 8px 8px 8px;
               }
               .tippy-svg-arrow {
                    top: -10px !important;
                    left: 5px !important;
               }
          }
          &.right-start {
               .tippy-content {
                    border-radius: 0 8px 8px 8px;
               }
               .tippy-svg-arrow {
                    top: 2px !important;
                    left: -3px !important;
               }
          }
          &.right-end {
               .tippy-content {
                    border-radius: 8px 8px 8px 0;
               }
               .tippy-svg-arrow {
                    top: unset !important;
                    bottom: 9px !important;
                    left: -3px !important;
               }
          }
          &.left-end {
               .tippy-content {
                    border-radius: 8px 8px 0 8px;
               }
               .tippy-svg-arrow {
                    top: unset !important;
                    right: -3px !important;
                    bottom: 9px !important;
               }
          }
          &.left-start {
               .tippy-content {
                    border-radius: 8px 0 8px 8px;
               }
               .tippy-svg-arrow {
                    top: -3px !important;
                    right: -3px !important;
               }
          }
          &.top {
               .tippy-svg-arrow {
                    bottom: 1px !important;
                    left: 50% !important;
               }
          }
          &.bottom {
               .tippy-svg-arrow {
                    top: -10px !important;
                    left: 50% !important;
               }
          }
          &.right {
               .tippy-svg-arrow {
                    top: unset !important;
                    bottom: 50% !important;
                    left: -3px !important;
               }
          }
          &.left {
               .tippy-svg-arrow {
                    top: unset !important;
                    right: -3px !important;
                    bottom: 50% !important;
               }
          }
          &.tooltipdata {
               .tippy-content {
                    border-radius: 8px;
               }
          }
     }
}

.badge {
     --#{$prefix}badge-line-height: 20px;
     --#{$prefix}badge-border-radius: 4px;
     --#{$prefix}badge-font-size: 12px;
     --#{$prefix}badge-padding-y: 3px;
     --#{$prefix}badge-padding-x: 7px;
     max-width: fit-content;
     border-radius: var(--#{$prefix}badge-border-radius);

     &-label {
          --#{$prefix}badge-font-size: 10px;
          --#{$prefix}badge-line-height: 14px;
          --#{$prefix}badge-padding-x: 4px;
          --#{$prefix}badge-padding-y: 2px;
          --#{$prefix}badge-border-radius: 6px;
     }

     &-text {
          justify-content: space-evenly;
          max-width: fit-content;
          border: none;
          background-color: rgba(226, 230, 233, 1) !important;
          color: rgba(75, 85, 99, 1);
          margin: 20px 0 15px;
          border-radius: 4px;
     }

     &-custom {
          justify-content: space-evenly;
          background-color: rgba(233, 246, 251, 1) !important;
          border: 1px solid $primary;
          cursor: pointer;
          &:hover {
               background-color: rgba(32, 168, 216, 0.3) !important;
          }
          svg {
               path:nth-child(2) {
                    fill: #0098d3;
               }
          }
     }

     &-bg {
          .bg-icon {
               display: grid;
               position: relative;
               width: 10px;
               height: 10px;
               place-items: center;
               margin-right: 4px;
               &::before,
               &::after {
                    position: absolute;
                    content: '';
                    width: 3px; /* Chiều dài của viền góc */
                    height: 3px; /* Chiều dài của viền góc */
                    border: 1px solid white; /* Độ dày và màu của viền */
               }
               &::before {
                    right: 0;
                    bottom: 0;
                    border-top: 0;
                    border-left: 0;
               }
               &::after {
                    top: 0;
                    left: 0;
                    border-bottom: 0;
                    border-right: 0;
               }
               & > div {
                    position: absolute;
                    width: 3px; /* Chiều dài của viền góc */
                    height: 3px; /* Chiều dài của viền góc */
                    border: 1px solid white; /* Độ dày và màu của viền */
               }
               .top-right {
                    bottom: 0;
                    left: 0;
                    border-top: 0;
                    border-right: 0;
               }
               .bottom-left {
                    top: 0;
                    right: 0;
                    border-bottom: 0;
                    border-left: 0;
               }
               &.dark {
                    &::before,
                    &::after,
                    & > div {
                         border-color: #2d3a44;
                    }
               }
          }
     }

     &-segment {
          &-item {
               &-icon {
                    margin-right: 5px;
                    padding: 4px 8px 4px 8px;
               }

               &-info {
                    .icon-close {
                         path {
                              fill: #9fadc1;
                              opacity: 0.5;
                         }
                    }
               }

               &-name {
                    color: #18222b;
                    font-size: 11px !important;
                    font-weight: 500;
                    line-height: 0.75rem !important;
                    max-width: 70px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
               }
          }
     }

     &-metric {
          height: 30px;
          justify-content: space-evenly;
          padding: 6px 14px;
          border: 1px solid #00000014;
          &-text {
               font-size: 13px;
               line-height: 18px;
               padding-inline: 4px;
          }
          &-add {
               width: 30px;
               height: 30px;
               border-radius: 50%;
               border: 1px solid rgba(0, 0, 0, 0.08);
               svg.light {
                    color: #fff;
               }
          }

          svg.icon.icon-close {
               path {
                    fill: #424242;
               }
          }
     }

     .text-dark {
          color: #2d3a44;
     }

     .dark {
          &::before,
          &::after,
          & > div {
               color: #2d3a44;
          }
     }
}

.tooltip {
     &-item {
          margin-top: 7rem;
     }
     &-data {
          &-wrapper {
               min-height: 86px;
               border-radius: 6px;
               padding: 11px 14px;
               background-color: #fff;
               color: #18222b;
               text-align: left;
               box-shadow: 0px 5px 15px -4px #00000040;
          }
          &-content {
               font-style: 13px;
               line-height: 18px;
               font-weight: 400;
               color: #4b5563;
          }
          &-status {
               display: inline-block;
               width: 10px;
               height: 10px;
               margin-right: 4px;
               border-radius: 50%;
               margin-right: 8px;
          }
     }
}

.smallKPI {
     &-wrapper {
          border: 1px solid $border-colors;
          background-color: $light;
          padding: 12px;
          border-radius: 8px;
          h6 {
               font-size: 16px;
               line-height: 22px;
               font-weight: 500;
               margin-bottom: 16px;
          }
     }
     &-body {
          min-width: 316px;
          border: 1px solid $border-colors;
          background-color: $white;
          border-radius: 8px;
          padding: 12px 16px;
          &-box {
               margin-bottom: 3px;
               &:last-child {
                    margin-bottom: 0;
               }
               &-icon {
                    width: 10px !important;
                    height: 10px !important;
                    margin-right: 4px;
               }
               &-num {
                    font-size: 24px;
                    line-height: 30px;
                    font-weight: 500;
                    color: #212c36;
                    display: flex;
                    align-items: center;
                    margin-left: auto;
                    &-second {
                         color: #212c36;
                         display: flex;
                         align-items: center;
                    }
               }

               &.dark {
                    color: $dark;
                    .smallKPI-body-box-icon {
                         color: $dark;
                    }
               }
          }
          & + & {
               margin-top: 8px;
          }
     }
}

.large-kpi {
     &-wrapper {
          border-radius: 8px;
          border: 1px solid $border-colors;
          background-color: $light;
     }
     &-card {
          min-width: 668px;
          height: 82px;
          border-radius: 4px;
          border: 1px solid $border-colors;
          padding: 12px 16px;
          & + & {
               margin-top: 4px;
          }
          &-name {
               .badge {
                    max-width: 60px;
               }
          }
          &-chart {
               width: 475px;
               .k-chart {
                    width: 100%;
                    height: 100%;
                    &-surface {
                         width: 100%;
                         height: 100%;
                         circle {
                              width: 8px;
                              height: 8px;
                              border-width: 1.5px;
                              // fill: #fff;
                         }
                    }
               }
          }
     }
     &-tooltip {
          min-width: 264px;
          padding: 12px 14px;
          border-radius: 6px;
          box-shadow: 0px 5px 15px -4px rgba(0, 0, 0, 0.2);
          background-color: #fff;
          color: #424242;
     }
}

.chart-wrapper {
     border: 1px solid $border-colors;
     background-color: $white;
     border-radius: 8px;
     padding: 16px;
     & + & {
          margin-top: 8px;
     }
}

.column-chart {
     border-radius: 8px;
     border: 1px solid $border-color;
     padding: 16px;
     background-color: $light;
     svg.svg-custom {
          width: 19px !important;
          height: 19px !important;
     }
     &-wrapper {
          position: relative;
          border: 1px solid $border-colors;
          background-color: $white;
          border-radius: 8px;
          padding: 16px;
          min-width: 1012px;
          .note + .note {
               margin-left: 24px;
          }
          & + & {
               margin-top: 16px;
          }
          &.show-line-compare .line-compare {
               position: absolute;
               top: 145px;
               left: 68px;
               content: '';
               width: 10px;
               height: max-content;
               background: #e1e1e1;
               opacity: 0.8;
               z-index: 1;
               pointer-events: none;
               &.compare {
                    height: 348px;
                    top: 133px;
               }
          }
     }
     &-content {
          &:first-child {
               margin-bottom: 24px;
          }
          &.k-chart {
               max-height: 100%;
               .k-chart-surface {
                    height: 100%;
                    svg {
                         margin-bottom: 26px;
                    }
               }
          }

          .header-chart {
               max-width: 300px;
          }
          // &.area:hover {
          //      .k-chart-point:not(:hover) path {
          //           opacity: 0.5;
          //      }
          // }
     }
     &-title {
          min-width: 62px;
          height: 20px;
          border-radius: 4px;
          padding: 4px 6px;
          background-color: #E2E8F0;
          color: #475569;
     }
     &-item {
          & + & {
               margin-left: 60px;
          }
     }
     .notion {
          margin-top: 35px;
     }

     .btn {
          &.btn-outline-light:hover {
               background-color: $bg-selected;
               color: $white !important;
               border-color: $bg-selected;
          }
     }
}

.radar-chart {
     height: 402px;
}

.bar-chart {
     &-content {
          .k-chart {
               max-height: 100%;
               .k-chart-surface {
                    height: 100%;
               }
          }
     }
}

.pie-chart {
     &-content {
          &.k-chart {
               max-height: 100%;
               .k-chart-surface {
                    height: 100%;
               }
          }
     }
}

.analytics {
     .table-kpi {
          max-width: 642px;
          &-wrapper {
               background-color: $white;
               padding: 12px;
               border-radius: 8px;
               border: 1px solid $border-color;
               & + & {
                    margin-top: 24px;
               }
          }
          table {
               thead {
                    tr {
                         text-align: center;
                         border: 1px solid #e4e7ea;

                         &:first-child {
                              background-color: $secondary;
                              color: #fff;
                         }

                         &:nth-child(2) {
                              th {
                                   background-color: #d2dbe0;
                              }
                         }
                    }
               }

               th {
                    border: unset;
                    padding: 4px 8px;
                    line-height: 16px;
                    text-align: start;
               }

               td {
                    width: fit-content;
                    padding: 4px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    // &:hover {
                    //      background-color: #fff;
                    // }
               }

               .kpi-tooltip {
                    &-wrapper {
                         min-width: 60px;
                         min-height: 20px;
                    }

                    &-item {
                         display: block !important;
                         width: 100%;
                         text-overflow: ellipsis;
                         position: relative;
                         outline: 0;
                         border: unset;
                         padding: 0 0 0 18px;
                         text-align: left;
                    }
               }

               .tags-wrapper {
                    position: relative;
                    &.divide::before {
                         position: absolute;
                         top: 0;
                         right: 50%;
                         content: '';
                         height: 100%;
                         border: solid 0.5px #e4e7ea;
                    }
                    &:hover {
                         background-color: #fff !important;
                    }
               }
          }
          .k-grid {
               .k-grid-header {
                    padding-inline-end: 0;
                    .k-table-thead .k-table-row:nth-child(2) {
                         display: none;
                    }
               }
               .k-table-row {
                    &.k-table-alt-row {
                         background-color: $light;
                    }
               }
               .k-table-td:first-child {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    border: 1px solid #e4e7ea;
               }
               &-content {
                    overflow-x: unset;
                    overflow-y: hidden;
                    .k-grid-table-wrap {
                         .k-grid-table {
                              line-height: 2;
                         }
                    }
               }
          }

          ::-webkit-scrollbar {
               height: 5px; /* Độ dày của thanh cuộn ngang */
          }

          ::-webkit-scrollbar-thumb {
               background: #888; /* Màu của thanh cuộn */
               border-radius: 2px; /* Bo góc */
          }

          ::-webkit-scrollbar-thumb:hover {
               background: #555; /* Màu khi hover */
          }

          ::-webkit-scrollbar-track {
               background: #f1f1f1; /* Màu nền của thanh cuộn */
          }
     }
     .table-kpi-wrapper + .table-kpi-wrapper {
          margin-top: 24px;
     }
     .report-table-bottom {
          .form-inline {
               select.form-control {
                    background-color: $white;
               }
          }
     }
}

.dot {
     display: inline-block;
     width: 8px;
     height: 8px;
     border-radius: 50%;
     margin-left: 8px;
     &-warning {
          background-color: $warning;
          border: 0.5px solid #b38600;
     }
     &-gray {
          background-color: $gray-300;
          border: 0.5px solid #6b7280;
     }

     &-table {
          width: 6px;
          height: 6px;
          margin-left: 0;
          margin-right: 5px;
     }
}

.rectangle {
     display: inline-block;
     width: 10px;
     height: 10px;
     margin-right: 4px;
}

.map {
     &-wrapper {
          display: flex;
          width: 100%;
          height: 652px;
          padding: 16px;
          background-color: #ffff;
          gap: 24px;
          border-radius: 8px;
          border: 1px solid rgba(226, 230, 233, 1);
     }
     &-container {
          flex: 2 0;
          height: 100%;
     }
     &-data {
          flex: 1 0;
          min-width: 340px;
          height: 100%;
          overflow-y: auto;
          overflow-x: hidden;
          scrollbar-width: thin;
     }
     &-data-empty {
          display: flex;
          min-width: 340px;
          justify-content: center;
          align-items: center;
          color: rgb(75, 85, 99);
     }
     &-tooltip-content {
          width: 264px;
          // height: 441px;
          border-radius: 6px;
          padding: 11px 14px;
          box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px -4px;
          background-color: #fff;
          .segment-content {
               &:not(:last-child) {
                    margin-bottom: 32px;
               }
          }
     }
}

@keyframes fadeIn {
     from {
          opacity: 0;
     }
     to {
          opacity: 1;
     }
}

.k-chart-tooltip-wrapper {
     box-shadow: 0px 5px 15px -4px rgba(0, 0, 0, 0.2);
     .k-chart-shared-tooltip {
          border-width: 0px !important;
     }
     .k-chart-tooltip {
          padding: 0;
          .heat-tooltip::before {
               position: absolute;
               top: 0px;
               left: -7px;
               content: '';
               background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgNyA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMi44Mjg0MyAwSDdWOUwxLjQxNDIxIDMuNDE0MjFDMC4xNTQyODQgMi4xNTQyOCAxLjA0NjYyIDAgMi44Mjg0MyAwWiIgZmlsbD0iIzE4MjIyQiIvPgo8L3N2Zz4=');
               background-repeat: no-repeat;
               height: 10px;
               width: 10px;
          }
     }
}

.tooltip-KPItable {
     .k-tooltip {
          padding-block: 0 !important;
          padding-inline: 0 !important;
          border-radius: 8px !important;
     }
     .k-callout {
          display: none;
     }
}

.k-chart .k-chart-license-text {
     display: none !important;
}

.k-animation-container-fixed {
     position: fixed;
}

.k-map {
     svg {
          border-radius: 8px;
     }
     &-controls {
          right: 0;
          bottom: 0;
          flex-direction: column;
          .k-navigator {
               background-color: $white !important;
          }
          .k-zoom-control {
               button {
                    background-color: $white;
               }
          }
     }
}

.loading-chart-box {
     position: relative;
     overflow: hidden;
     &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          transform: translateX(-100%);
          background-image: linear-gradient(90deg, rgba(#fff, 0) 0, rgba(#fff, 0.2) 20%, rgba(#fff, 0.5) 60%, rgba(#fff, 0));
          animation: shimmer 5s infinite;
          content: '';
     }
}
@keyframes shimmer {
     100% {
          transform: translateX(100%);
     }
}