.custom-date-picker {
     position: relative;
     padding: 0 10px;

     &.save-report {
          padding: 0;
          .rdrInfiniteMonths .rdrMonth {
               width: 380px;
          }
     }

     .btn-none {
          height: unset !important;
          border-radius: unset !important;
          min-width: unset !important;
          text-transform: unset !important;
     }

     p.label {
          font-weight: 400;
          font-size: 14px;
          color: #424242;
     }

     .date-range-wrapper {
          text-transform: capitalize;
          font-weight: 400;
          display: flex;
          align-items: center;
          padding: 0;

          &.save-report {
               padding: 0 10px;
               border: 1px solid hsl(0, 0%, 80%) !important;
               border-radius: 10px !important;
          }

          .date-range {
               display: flex;
               align-items: center;
               flex-direction: column;
               align-items: flex-end;
               justify-content: center;
               min-height: 47px;

               .preset-date-range {
                    color: #3c4043;
                    height: 16px;
                    background: #f1f3f4;
                    letter-spacing: 0.25px;
                    line-height: 16px;
                    margin-right: 6px;
                    padding: 0 4px;
                    font-weight: 500;
                    font-size: 10px;

                    &.have-compare {
                         margin-right: 30px;
                    }
               }

               .compare-date-range {
                    font-size: 12px;
                    position: relative;

                    &::before {
                         content: '';
                         display: block;
                         position: absolute;
                         width: 8px;
                         height: 8px;
                         border-radius: 50%;
                         right: 102%;
                         top: 50%;
                         transform: translate(-50%, -50%);
                         background-color: #D1D5DB;
                    }
               }
          }

          .icon-calendar-report {
               margin: 0;
               margin-left: 50px;
               z-index: 10;

               &.save-report{
                    z-index: 0;
               }
          }
     }

     .custom-date-wrapper {
          position: absolute;
          top: 0;
          right: 15px;
          z-index: 101;
          padding: 0 15px 12px 15px;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          margin-bottom: 0;

          .bottom-buttons {
               button:nth-of-type(1) {
                    color: #8C9BA7;
               }
          }

          &.save-report {
               position: relative;
               top: 0;
               left: 0;
               right: 0;
          }

          &.sf-date-wrapper {
               top: auto !important;
               right: auto !important;
               bottom: 0 !important;
          }

          &.sf-logs-date-wrapper {
               right: auto !important;
               top: 70px;

               &.datepicker-to {
                    right: 150px !important;
               }
          }
          
          .bottom-buttons {
               button:nth-of-type(1) {
                    color: #8C9BA7;
               }
          }

          &.save-report {
               position: relative;
               top: 0;
               left: 0;
               right: 0;
          }

          &::before {
               content: '';
               display: block;
               position: absolute;
               width: 100%;
               height: 20px;
               left: 0;
               bottom: -15px;
               background-color: transparent;
          }

          .rdrDateRangePickerWrapper {
               flex-direction: column;

               .rdrCalendarWrapper {
                    order: 1;
               }

               .rdrDefinedRangesWrapper,
               .rdrInputRanges {
                    display: none;
               }
          }

          &.compare .rdrDateRangePickerWrapper .rdrDateDisplay {
               .rdrDateInput:first-child {
                    position: relative;
                    padding-left: 26px;

                    &::before {
                         content: '';
                         display: block;
                         position: absolute;
                         width: 10px;
                         height: 10px;
                         border-radius: 50%;
                         left: 0;
                         top: 50%;
                         transform: translateY(-50%);
                         background-color: #FFB300;
                    }
               }

               &:last-child:not(:first-child) .rdrDateInput:first-child::before {
                    background-color: #D1D5DB;
               }
          }

          &.date-scope {
               .rdrDateRangePickerWrapper .rdrMonthAndYearWrapper {
                    margin-top: 70px;
               }

               &.compare .select-date-scope-wrapper {
                    top: 132px;
               }
          }
     }

     &.custom-date-picker-sflogs {
          padding: 0 !important;

          .react-datepicker__input-container {
               
               .close-filter {
                    display: none;
               }

               &:hover{
                    .close-filter {
                         display: block;
                    }
               }
          }
     }

     .rdrDateDisplayWrapper {
          background-color: unset;

          .rdrDateDisplay {
               margin: 0;
               color: #fff !important;
               position: relative;
               padding: 4px 0;
               width: 268px;

               &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: calc(100% + 89px);
                    height: 1px;
                    left: -15px;
                    bottom: -3px;
                    background-color: #f0f3f5;
               }

               &:first-child {
                    margin-bottom: 27px;
               }

               &:last-child:not(:first-child) {
                    padding-bottom: 0;
               }

               .rdrDateInput:first-child {
                    position: relative;

                    &::after {
                         content: '';
                         display: block;
                         position: absolute;
                         right: 0;
                         top: 50%;
                         border-top: 2px solid #e6e6e6;
                         border-right: 2px solid #e6e6e6;
                         width: 12px;
                         height: 12px;
                         transform: translateY(-50%) rotate(67deg) skewX(45deg) scaleY(cos(45deg));
                    }
               }

               input {
                    color: #424242;
                    font-size: 14px;
                    border: none;
                    padding: 0;
               }
          }

          .rdrDateDisplayItem {
               box-shadow: none;
          }

          .rdrDateInput {
               text-align: start;
          }

          .rdrDateDisplayItemActive input {
               color: #20a8d8;
               font-weight: 500;
          }
     }

     .compare-period {
          position: absolute;
          top: 54px;
          width: calc(100% - 30px);
          min-height: 24px;

          .custom-checkbox {
               align-items: center;
               .custom-control-label {
                    cursor: pointer;

                    &::before {
                         border: solid 2px #a0a0a0;
                         top: 50%;
                         transform: translateY(-50%);
                    }
                    &::after {
                         top: 2px;
                    }
               }

               .custom-control-input:checked~.custom-control-label::before {
                    border: none;
               }
          }

          .dropdown {
               &.show .dropdown-toggle {
                    &::after {
                         transform: rotateZ(180deg);
                    }

                    span {
                         color: #2d3a44;
                    }
               }

               .dropdown-toggle {
                    padding: 0;
                    min-width: 118px;
                    text-align: end;

                    &::after {
                         margin-left: 9px;
                    }

                    span {
                         text-transform: capitalize;
                         color: #20a8d8;
                         font-weight: 400;
                         line-height: 24px;
                    }
               }

               svg.arrow-account {
                    width: 87px;
                    height: 5px;
                    top: -5px;
               }

               .dropdown-menu {
                    padding: 0;
                    border: 1px solid #e0e4e6;
                    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.04), 0px 20px 25px rgba(0, 0, 0, 0.1);
                    border-radius: 10px;
                    border-top-right-radius: 0;
                    transform: translate(-42px, 27px) !important;

                    div:first-of-type a {
                         border-top-left-radius: 10px;
                    }

                    div:last-of-type a {
                         border-bottom-left-radius: 10px;
                         border-bottom-right-radius: 10px;
                    }

                    a {
                         font-size: 13px;
                         padding: 5px 11px;
                         color: #2d3a44;

                         &:hover {
                              background: #20a8d8;
                              color: #fff;
                         }
                    }
               }
          }
     }

     .select-date-scope-wrapper {
          position: absolute;
          top: 90px;
          width: calc(100% - 30px);

          p.label {
               margin: 0;
          }

          .select-date-scope {
               min-width: 180px;

               div[class*='-container'] {
                    min-height: 33px;
               }

               div[class*='-singleValue'],
               div[class*='-option'] {
                    font-size: 13px;
               }
          }
     }

     .rdrMonthAndYearWrapper {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          border: 1px solid rgba(0, 0, 0, 0.08);
          border-bottom: 0;
          margin-top: 17px;

          .rdrMonthPicker,
          .rdrYearPicker {
               select {
                    &::-webkit-scrollbar {
                         height: 16px;
                         overflow: visible;
                         width: 16px;
                    }

                    &::-webkit-scrollbar-button {
                         height: 0;
                         width: 0;
                    }

                    &::-webkit-scrollbar-corner {
                         background: transparent;
                    }

                    &::-webkit-scrollbar-thumb {
                         background-color: rgba(0, 0, 0, 0.2);
                         background-clip: padding-box;
                         border: solid transparent;
                         border-width: 1px 1px 1px 6px;
                         min-height: 28px;
                         padding: 100px 0 0;
                         box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
                    }

                    &::-webkit-scrollbar-track {
                         background-clip: padding-box;
                         border: solid transparent;
                         border-width: 0 0 0 4px;
                    }
               }
          }
     }

     .rdrWeekDays {
          border-left: 1px solid rgba(0, 0, 0, 0.08);
          border-right: 1px solid rgba(0, 0, 0, 0.08);

          .rdrWeekDay {
               text-transform: uppercase;
          }
     }

     .rdrInfiniteMonths {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          border: 1px solid rgba(0, 0, 0, 0.08);
          border-top: 0;

          .rdrMonth {
               width: 309px;
          }

          &::-webkit-scrollbar {
               height: 16px;
               overflow: visible;
               width: 16px;
          }

          &::-webkit-scrollbar-button {
               height: 0;
               width: 0;
          }

          &::-webkit-scrollbar-corner {
               background: transparent;
          }

          &::-webkit-scrollbar-thumb {
               background-color: rgba(0, 0, 0, 0.2);
               background-clip: padding-box;
               border: solid transparent;
               border-width: 1px 1px 1px 6px;
               min-height: 28px;
               padding: 100px 0 0;
               box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
          }

          &::-webkit-scrollbar-track {
               background-clip: padding-box;
               border: solid transparent;
               border-width: 0 0 0 4px;
          }
     }

     .rdrCalendarWrapper {

          .rdrDayStartPreview,
          .rdrStartEdge {
               border-top-left-radius: 6px;
               border-bottom-left-radius: 6px;
          }

          .rdrDayEndPreview,
          .rdrEndEdge {
               border-top-right-radius: 6px;
               border-bottom-right-radius: 6px;
          }

          .rdrDayStartOfWeek,
          .rdrDayStartOfMonth,
          .rdrDayStartOfYear {

               .rdrInRange,
               .rdrEndEdge,
               .rdrDayInPreview,
               .rdrDayEndPreview {
                    border-top-left-radius: 6px;
                    border-bottom-left-radius: 6px;
               }
          }

          .rdrDayEndOfWeek,
          .rdrDayEndOfMonth,
          .rdrDayEndOfYear {

               .rdrInRange,
               .rdrStartEdge,
               .rdrDayInPreview,
               .rdrDayStartPreview {
                    border-top-right-radius: 6px;
                    border-bottom-right-radius: 6px;
               }
          }

          .rdrDayToday .rdrDayNumber span:after {
               background: #a8abff !important;
          }
     }

     .rdrDay {
          &:not(.rdrDayPassive) .rdrDayNumber span {
               color: #424242 !important;
               font-weight: 400;
          }

          &.rdrDayDisabled {
               background-color: unset;

               .rdrDayNumber span {
                    color: rgba(0, 0, 0, 0.435) !important;
               }
          }

          &.rdrDayPassive .rdrDayNumber span {
               color: #fff;
          }
     }

     .presets-wrapper {
          display: flex;
          position: relative;
          padding: 15px 0 12px 0;
          margin-top: 14px;
          margin-bottom: 10px;

          &::before {
               content: '';
               display: block;
               position: absolute;
               width: calc(100% + 30px);
               height: 1px;
               left: -15px;
               top: 0;
               background-color: #f0f3f5;
          }

          .easy-presets {
               display: flex;
               flex-direction: column;
               justify-content: space-between;

               .btn {
                    font-weight: 500;
                    font-size: 12px;
                    color: #2d3a44;
                    text-transform: capitalize;
                    text-align: start;
                    padding: 0;
                    margin-bottom: -2px;

                    &.active,
                    &:hover {
                         color: #20a8d8;
                    }
               }
          }

          .presets {
               width: 52%;
               margin-left: auto;
               margin-top: -3px;

               .row {
                    .preset-col {
                         text-align: end;
                    }
               }

               button {
                    font-size: 12px;
                    line-height: 20px;
                    font-weight: 500;
                    color: #2d3a44;
                    text-align: end;
                    width: max-content;
                    text-decoration: underline;
                    text-transform: capitalize;
                    padding: 0;
                    margin-bottom: -4px;

                    &:hover,
                    &.active {
                         color: #20a8d8;
                    }
               }
          }
     }

     .bottom-buttons {
          display: flex;
          justify-content: flex-end;
          position: relative;

          &::before {
               content: '';
               display: block;
               position: absolute;
               width: calc(100% + 30px);
               height: 1px;
               left: -15px;
               top: -11px;
               background-color: #f0f3f5;
          }

          .btn:first-child {
               background-color: unset;
               color: #8c9ba7;
               margin-right: 1px;
          }

          .btn-primary {
               width: 100%;
               max-width: 101px;
          }
     }

     .selection-date-range {
          white-space: nowrap;
          position: relative;

          &.have-compare::before {
               content: '';
               display: block;
               position: absolute;
               width: 8px;
               height: 8px;
               border-radius: 50%;
               right: 105%;
               top: 50%;
               transform: translate(-50%, -50%);
               background-color: #FFB300;
          }
     }
}